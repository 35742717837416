import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import { Header } from "../../header/Header";
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { API_URL, axiosconfig } from "../../core";
import './Produtos.css';
import { Product } from "../../interfaces/product";
import { Layout } from "../../interfaces/layout";
import Animate from 'react-smooth';
import imgProprioPonto from '../../images/produtos/proprioponto.png';
import banner from '../../images/produtos/BANNER.png';

export function Produtos() {
    const [products, setProductList] = useState<Product[]>([]);
    const [layout, setLayout] = useState<Layout>();
    
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    useEffect(() => {
        axios.get(API_URL + '/api/products?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setProductList(response.data);
            });
        axios.get(API_URL + '/api/layout?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setLayout(response.data);
            });
    }, []);

    return (
        <>
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <div className="w-100 position-relative img">
               {/*  `${API_URL}${layout?.bannerProducts}` */}
                    <img src={banner} alt="Banner produtos" className="w-100" style={{ maxHeight: '300px' }} />
                    <div className="position-absolute text-center" data-aos="fade-left">
                        <p className="" style={{ fontSize: '35px', fontWeight: 'bold', color: '#093777' }}>Nossos Produtos</p>
                    </div>
                </div>
                <Container>
                    <Row className="tenha-seu-propio-posto-row">
                        <Col sm className="tenha-seu-propio-posto-img">
                            <img className="w-100" src={imgProprioPonto} style={{maxWidth: '400px'}}/>
                        </Col>
                        <Col sm>
                            <h5 style={{color:'#1882BE', fontWeight: 'bold'}}>
                                Tenha seu próprio posto de abastecimento
                            </h5>
                            <p>
                                Empresa, cooperativa e indústria podem ter seu próprio posto de abastecimento.
                                A stang disponibiliza toda a estrutura com a tecnologia e expertise de seus produtos para os nossos clientes, facilitando o
                                abastecimento de sua frota.
                            </p>
                            <Button style={{
                                backgroundColor: '#143f86', paddingLeft: '3rem', paddingRight: '3rem',
                                border: 'none',  borderRadius: '5px', minHeight: '1rem', marginTop: '1rem'
                             }} onClick={handleShow}>
                            Tenho interesse
                        </Button>
                        </Col>
                    </Row>
 
                    <div className="grid-container">
                        {products?.map((product, i) => (
                            /* <div id={product?.slug}></div> */
                            <>
                                {i === 0 && i < 1 ?
                                    <>
                                        <div className="grid-item position-relative" >
                                            <img src={`${API_URL}${product?.imageDetails}`} alt={product?.title} className="pd1" />
                                            <div className="position-absolute product-name pd1">
                                                <p style={{ color: product?.color }}>{product?.title}</p>
                                            </div>
                                        </div>
                                        <div className="grid-item">
                                            <p>
                                                {product?.content}
                                            </p>
                                            <h3 style={{ color: product?.color }}>Principais vantagens:</h3>
                                            <p>
                                                {product?.advantages}
                                            </p>
                                            <p>Para maiores informações, acesse a Ficha de Informação de Segurança de Produtos Químicos - FISPQ!</p>
                                            <a className="btn-ficha-tecnica" href={`${API_URL}${product?.datasheet}`} rel="noreferrer" target="_blank">Download Ficha Técnica</a>
                                        </div>
                                    </>
                                    :
                                    ''}
                            </>
                        ))}
                    </div>
                </Container>
                <div className="w-100 orcamento-produtos" data-aos="zoom-in-up">
                    <p className="texto-orcamento">Faça já o seu orçamento</p>
                    <a href="/orcamento"><Button className="button-orcamento">Clique aqui</Button></a>
                </div>
                <Container>
                    <div className="grid-container">
                        {products?.map((product, i) => (
                            <>
                                {i >= 1 ?
                                    <>
                                        <div className="grid-item position-relative" >
                                            <img src={`${API_URL}${product?.imageDetails}`} alt={product?.title} className="pd1" />
                                            <div className="position-absolute product-name pd1">
                                                <p style={{ color: product?.color }}>{product?.title}</p>
                                            </div>
                                        </div>
                                        <div className="grid-item">
                                            <p>
                                                {product?.content}
                                            </p>
                                            <h3 style={{ color: product?.color }}>Principais vantagens:</h3>
                                            <p>
                                                {product?.advantages}
                                            </p>
                                            <p>Para maiores informações, acesse a Ficha de Informação de Segurança de Produtos Químicos - FISPQ!</p>
                                            <a className="btn-ficha-tecnica" href={`${API_URL}${product?.datasheet}`} rel="noreferrer" target="_blank">Download Ficha Técnica</a>
                                        </div>
                                    </>
                                    :

                                    ''}
                            </>
                        ))}
                    </div>
                    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton >
                        <Modal.Title style={{ fontSize: '1.2rem' }}>Informe os dados abaixo e clique em enviar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ fontSize: '0.8rem' }}>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridNome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>

                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridCpf">
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control type="text" placeholder="cpf" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="email" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridEstado">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridCidade">
                                        <Form.Label>Cidade</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridAreaAtuacao">
                                        <Form.Label>Área de atuação</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridTelefone">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Row className=" mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Button variant="danger" className="w-100" onClick={handleClose} style={{ fontSize: '0.8rem', textTransform: 'uppercase', marginLeft: '2rem', marginRight: '2rem' }}>
                                Enviar
                            </Button>
                        </Row>
                    </Modal.Body>
                </Modal>
                </Container>
                <Footer />
            </Animate>
        </>
    )
}