import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Produtos } from './pages/produtos/Produtos';
import { Home } from './pages/home/Home';
import { AreaSocial } from './pages/areasocial/AreaSocial';
import { Blog } from './pages/blog/Blog';
import { SobreNos } from './pages/sobrenos/SobreNos';
import OndeEncontrar from './pages/ondeencontrar/OndeEncontrar';
import BlogDetail from './pages/blogdetail/BlogDetail';
import Denuncia from './pages/denuncia/Denuncia';
import FaleConosco from './pages/faleconosco/FaleConosco';
import Orcamento from './pages/orcamento/Orcamento';
import { ProprioPosto } from './pages/proprioposto/ProprioPosto';
import { SejaFranqueado } from './pages/sejafranqueado/SejaFranqueado';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Produtos} path={'/produtos'} />
        <Route component={AreaSocial} path={'/areasocial'} />
        <Route component={Blog} path={'/blog'} />
        <Route component={BlogDetail} path={'/pub/:slug'} />
        <Route component={SobreNos} path={'/sobre'} />
        <Route component={OndeEncontrar} path={'/ondeencontrar'} />
        <Route component={FaleConosco} path={'/faleconosco'} />
        <Route component={Denuncia} path={'/denuncia'} />
        <Route component={Orcamento} path={'/orcamento'} />
        <Route component={ProprioPosto} path={'/proprioposto'} />
        <Route component={SejaFranqueado} path={'/sejafranqueado'} />
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  );

}
export default App;
