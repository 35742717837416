import { Header } from "../../header/Header";
import Footer from "../../components/footer/Footer";
import { Container, Form, Button, Col } from "react-bootstrap";
import axios from 'axios';
import { API_URL, axiosconfig, GOOGLE_SITE_KEY } from "../../core";
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

export default class Orcamento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            city: '',
            address: '',
            message: '',
            sent: false,
            err: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);
        data.append('city', this.state.city);
        data.append('address', this.state.address);
        data.append('message', this.state.message);
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/invoice`, data, axiosconfig)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    reset(e){
        e.preventDefault()
        this.setState({name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        message: '',
        sent: false,
        err: false})
    }
    render(){
        return (            
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <Container style={{ paddingTop: '8rem', fontSize: '0.9rem' }}>
                    <h3 style={{ color: '#a8a7a7', paddingBottom: '2rem' }}>Orçamento</h3>
                    {!this.state.sent ?
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Nome da empresa</Form.Label>
                                <Form.Control required onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="" />
                            </Form.Group>
    
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required onChange={(e) => this.setState({ email: e.target.value })} type="email" placeholder="Enter email" />
                            </Form.Group>
                        </Form.Row>
    
                        <Form.Group controlId="formGridAddress1">
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control required onChange={(e) => this.setState({ address: e.target.value })}  placeholder="" />
                        </Form.Group>
    
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control required onChange={(e) => this.setState({ city: e.target.value })} />
                            </Form.Group>
    
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control required onChange={(e) => this.setState({ phone: e.target.value })} />
                            </Form.Group>
    
                        </Form.Row>
    
                        <Form.Group controlId="formGridMensagem">
                            <Form.Label>Mensagem</Form.Label>
                            <Form.Control onChange={(e) => this.setState({ message: e.target.value })} required as="textarea" rows={3} placeholder="" />
                        </Form.Group>
    
                        <Form.Group>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={GOOGLE_SITE_KEY}
                                />
                            </div>
                        </Form.Group>

                        <Button style={{ backgroundColor: "#ff2927", border: 'none', width: '100%', fontSize: '0.8rem' }} type="submit">
                            ENVIAR
                        </Button>
                        {this.state.sent ? <span>Enviado!</span> : null}
                    </Form>
                    :
                    <div>
                        <h6 style={{ color: "green" }}>Obrigado pelo seu contato!</h6>
                        <button className={'repeatbutton'} onClick={(e => this.reset(e))}><span style={{color: 'blue'}}>repetir</span></button>
                    </div>
                    }
                    <br/>
                    {this.state.err ? <div><h6 style={{ color: "red" }}>Você deve marcar o captcha</h6></div> : null}
                </Container>
                <div style={{ paddingTop: '5rem' }}>
                    <Footer />
                </div>
            </Animate>
        );
    }
}