import { Button, Card, Col } from "react-bootstrap";
import TextTruncate from 'react-text-truncate';
import { PubDetail } from "../../interfaces/pub";
import { API_URL } from "../../core";

export function CardNews(pub: PubDetail) {
    return (
        <>
            <Col md={4}>
                <Card style={{ margin: "0.5rem" }}>
                    <Card.Img variant="top" src={`${API_URL}/${pub.image}`} />
                    <Card.Body style={{ textAlign: 'justify' }}>
                        <Card.Title style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            <TextTruncate
                                line={2}
                                element="span"
                                truncateText="…"
                                text={pub.title}
                                textTruncateChild={""}
                            />
                        </Card.Title>
                        <Card.Text>
                            <TextTruncate
                                line={5}
                                element="span"
                                truncateText="…"
                                text={pub.content}
                                textTruncateChild={""}
                            />

                        </Card.Text>
                        <a href={`/pub/${pub.slug}`}><Button variant="primary" style={{ fontSize: '0.8rem' }}>Ver mais</Button></a>
                    </Card.Body>
                </Card>
            </Col>


        </>
    );
}