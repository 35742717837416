import { Button, Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import { Header } from "../../header/Header";
import { CardBlog } from "./CardBlog";
import logoImg from '../../images/LOGO_STANG.svg';
import { useEffect, useState } from "react";
import { API_URL, axiosconfig } from "../../core";
import axios, { AxiosResponse } from "axios";
import { Pub } from "../../interfaces/pub";
import { Code } from "../../interfaces/code";
import { Page } from "../../interfaces/page";
import Animate from 'react-smooth';

export function Blog() {
  const [pubs, setPubs] = useState<Pub>();
  const [code, setCode] = useState<Code>();
  const [page, setPage] = useState<Page>({page: 1});
    useEffect(() => {
        axios.get(API_URL + '/api/pubs?format=json', axiosconfig)
        .then((response: AxiosResponse) => {
            setPubs( response.data );
        });
        axios.get(API_URL + '/api/code?format=json', axiosconfig)
        .then((response: AxiosResponse) => {
            setCode( response.data );
        });
        setPage({page: 1});
    }, []);
    function updatePubs(action: string) {
      let url = API_URL + `/api/pubs?page=${action === 'next' ? page?.page + 1 : page?.page - 1}&format=json`
      axios.get(url, axiosconfig)
          .then(res => {
              axios.get(API_URL + '/api/pubs?format=json', axiosconfig)
              .then((response: AxiosResponse) => {
                  setPubs( response.data );
                  setPage({page : action === 'next' ? page?.page + 1 : page?.page - 1});
              });
          })
    }
  return (
    <Animate to="1" from="0" attributeName="opacity">
      <Header />
      <div className="px-4 py-5 text-center" style={{ backgroundColor: '#084091', minHeight: '250px' }}>
        <div className="wrapper-news" style={{ paddingTop: '8rem' }}>
          <div className="text-follow">
            <h3 style={{ color: "#ffd904" }}>
              Últimas Notícias
            </h3>
          </div>
          <div className="text-news" >
            <p style={{ fontSize: '2vh' }}>
              Acompanhe nossas novidades, as últimas e principais <br />
              notícias sobre Combustível. Entrevistas, análises, opinião e muito mais.
            </p>
          </div>
        </div>
      </div>
      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
           {pubs?.results.map(pub => (
            <CardBlog {...pub} />
          ))} 
          </div>
          <br/>          
          {pubs?.previous ? <button className="btn btn-outline-secondary" onClick={() => updatePubs('previous')}>&larr; Anterior</button> : <div></div>}
          {pubs?.next ? <button className="btn btn-outline-dark" onClick={() => updatePubs('next')}>Próxima &rarr;</button> : <div></div>}
        </div>
      </div>
      <Container style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Row>
          <Col >
            <Row md={10} style={{ border: "4px solid #66666" }}>
              <Card>
                <Row>
                  <CardGroup style={{ border: "none", backgroundColor: "#FFF" }}>
                  <Card style={{ border: "none", textAlign: 'center' }}>
                        <Card.Img className="img-news" src={logoImg} />
                        <Card.Body>
                            <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>CÓDIGO DE ÉTICA E CONDUTA</Card.Title>
                            <Card.Text >
                                {code?.content}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                            <a href={`${API_URL}${code?.document}`} target="_blank" rel="noreferrer"><Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem", fontSize: '0.8rem' }}>Acesse aqui</Button></a>
                        </Card.Footer>
                    </Card>
                    <Card style={{ margin: "20px", border: "none" }}>
                      <p className="complience">COMPLIANCE</p>
                      <Card.Body>
                        <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>DENUNCIE IRREGULARIDADES ATRAVÉS DO CANAL DE DENÚNCIA STANG</Card.Title>
                        <Card.Text>
                          Nossa plataforma de denúncia é operada por uma empresa externa especializada em captação de relatos de não
                          conformidade. Os relatos podem ser realizados de forma anônima ou com identificação.
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                        <Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem" }}>Denunciar</Button>
                      </Card.Footer>
                    </Card>
                  </CardGroup>                  
                </Row>
              </Card>
            </Row>
          </Col>                    
        </Row>
      </Container>
      <Footer />
    </Animate>
  );
}