import { Header } from "../../header/Header";
import bannerImg from '../../images/ondeencontrar/bannerondeencontrar.png'
import './FaleConosco.css';
import Footer from "../../components/footer/Footer";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { BiPhoneCall } from "react-icons/bi";
import { GoMailRead } from "react-icons/go";
import { FiMapPin } from "react-icons/fi";
import axios from 'axios';
import { API_URL, axiosconfig, GOOGLE_SITE_KEY } from "../../core";
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

export default class FaleConosco extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            name: '',
            phone: '',
            email: '',
            message: '',
            sent: false,
            err: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        axios.get(API_URL + '/api/products?format=json', axiosconfig)
            .then(res => {
                this.setState({ products: res.data });
            })
    }
    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);
        data.append('message', this.state.message);
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/contact`, data, axiosconfig)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    reset(e) {
        e.preventDefault()
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            sent: false,
            err: false
        })
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <div className="wrapperfc" >
                    <div className="divimagefc" style={{ backgroundImage: `url(${bannerImg})` }}></div>
                    <div className="divtextfc" data-aos="fade-left"><b>Fale conosco</b></div>
                </div>

                <Container style={{ paddingTop: '2rem' }}>
                    <Row style={{ paddingBottom: '3rem' }}>
                        <Col sm={6} style={{ paddingTop: '2rem' }}>
                            <div className="col-lg-12" style={{ textAlign: 'left', color: '#00365A', fontWeight: 'bold' }}>
                                <h3 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Informações de <br />contato</h3>
                            </div>
                            <div className="col-lg-12" style={{ textAlign: 'left', fontWeight: 'bold', paddingTop: '1rem' }}>
                                <p>
                                    <BiPhoneCall style={{ marginRight: '0.5rem', color: '#666', fontSize: '1.5rem' }} />(46) 3524-6318</p>
                                <p><GoMailRead style={{ marginRight: '0.5rem', color: '#666', fontSize: '1.5rem' }} />Email:<br />
                                    Contato@stangdistribuidora.com.br</p>
                                <p><FiMapPin style={{ marginRight: '0.5rem', color: '#666', fontSize: '1.5rem' }} /> Endereço:<br />
                                    R. Dr. Eli Volpate, 948 - Chapada.<br />
                                    Araucária - PR, 83707-746
                                </p>
                            </div>

                        </Col>
                        <Col sm={6} >
                            {!this.state.sent ?
                                <Form className="formfc" onSubmit={this.handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridNome">
                                            <Form.Label>Nome</Form.Label>
                                            <Form.Control required onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="* Nome" />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="formGridEmail">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control required onChange={(e) => this.setState({ email: e.target.value })} type="email" placeholder="* E-mail" style={{ fontSize: '0.8rem', color: '#6666' }} />
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridNome">
                                            <Form.Label>Telefone</Form.Label>
                                            <Form.Control required onChange={(e) => this.setState({ phone: e.target.value })} type="text" placeholder="* Telefone" style={{ fontSize: '0.8rem' }} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="formGridMensagem">
                                        <Form.Label>Mensagem</Form.Label>
                                        <Form.Control required onChange={(e) => this.setState({ message: e.target.value })} as="textarea" rows={4} placeholder="* Mensagem" style={{ fontSize: '0.8rem' }} />
                                    </Form.Group>

                                    <Form.Group>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={GOOGLE_SITE_KEY}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Button style={{ backgroundColor: "#ff2927", border: 'none', width: '100%', fontSize: '0.8rem' }} type="submit">
                                        ENVIAR
                                    </Button>
                                    <div style={{ textAlign: 'left', width: '100%', marginTop: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>* Campos com preenchimento obrigatório.</span>
                                    </div>
                                    {this.state.sent ? <span>Enviado!</span> : null}
                                </Form>
                                :
                                <div>
                                    <h6 style={{ color: "green" }}>Obrigado pelo seu posicionamento!</h6>
                                    <button className={'repeatbutton'} onClick={(e => this.reset(e))}><span style={{ color: 'blue' }}>repetir</span></button>
                                </div>
                            }
                            <br />
                            {this.state.err ? <div><h6 style={{ color: "red" }}>Você deve marcar o captcha</h6></div> : null}
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </Animate>
        );
    }
}