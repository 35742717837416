import { Col, Container, Form, Row, Button } from "react-bootstrap";
import logo from '../../images/LOGO_STANG.svg';
import axios from 'axios';
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { API_URL, axiosconfig, GOOGLE_SITE_KEY } from "../../core";
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './Footer.css';

const recaptchaRef = React.createRef();

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            name: '',
            phone: '',
            email: '',
            message: '',
            sent: false,
            err: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        axios.get(API_URL + '/api/products?format=json', axiosconfig)
            .then(res => {
                this.setState({ products: res.data });
            })
    }
    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);
        data.append('message', this.state.message);
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/contact`, data, axiosconfig)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    reset(e) {
        e.preventDefault()
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            sent: false,
            err: false
        })
    }
    render() {
        return (
            <div className="w-100" style={{ backgroundColor: "#040404", paddingTop: '2rem' }}>
                <Container className="footer-mob" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Row>
                        <Col md={6}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img src={logo} alt="Stang" style={{ maxWidth: '80%', paddingBottom: '2rem' }} />
                            </div>
                            {!this.state.sent ?
                                <Form onSubmit={this.handleSubmit} style={{ marginTop: '20px', fontSize: '0.8rem' }}>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridNome">
                                            {/* <Form.Label>Nome</Form.Label> */}
                                            <Form.Control required onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="* Nome" style={{ backgroundColor: 'transparent', fontSize: '0.8rem' }} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridTelefone">
                                            {/* <Form.Label>Telefone</Form.Label> */}
                                            <Form.Control required onChange={(e) => this.setState({ phone: e.target.value })} type="text" placeholder="* Telefone" style={{ backgroundColor: 'transparent', fontSize: '0.8rem' }} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="formGridEmail">
                                        {/* <Form.Label>E-mail</Form.Label> */}
                                        <Form.Control required onChange={(e) => this.setState({ email: e.target.value })} type="email" placeholder="* E-mail" style={{ backgroundColor: 'transparent', fontSize: '0.8rem' }} />
                                    </Form.Group>

                                    <Form.Group controlId="formGridMensagem">
                                        {/* <Form.Label>Mensagem</Form.Label> */}
                                        <Form.Control required onChange={(e) => this.setState({ message: e.target.value })} as="textarea" rows={2} placeholder="* Mensagem" style={{ backgroundColor: 'transparent', fontSize: '0.8rem' }} />
                                    </Form.Group>

                                    <Form.Group>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={GOOGLE_SITE_KEY}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Button style={{ backgroundColor: "#ff2927", border: 'none', width: '100%', fontSize: '0.8rem' }} type="submit">
                                        ENVIAR
                                    </Button>
                                    <div style={{ textAlign: 'left', width: '100%', marginTop: '10px' }}>
                                        <span style={{ color: '#e9e5e5', fontWeight: 'bold' }}>* Campos com preenchimento obrigatório.</span>
                                    </div>
                                    {this.state.sent ? <span>Enviado!</span> : null}
                                </Form>
                                :
                                <div>
                                    <h6 style={{ color: "green" }}>Obrigado pelo seu posicionamento!</h6>
                                    <button className={'repeatbutton'} onClick={(e => this.reset(e))}><span style={{ color: 'blue' }}>repetir</span></button>
                                </div>
                            }
                            {this.state.err ? <div><h6 style={{ color: "red" }}>Você deve marcar o captcha</h6></div> : null}
                        </Col>
                        <Col md={6} className="text-footer-mob" style={{ fontSize: '0.8rem', paddingTop: '1rem' }}>
                            <div className="menu-mob">
                                <ul style={{ listStyle: 'none', color: '#e9e5e5' }}><b>Stang Distribuidora</b>
                                    <li style={{ paddingTop: '0.8rem' }}><a href="/sobre">Sobre a empresa</a></li>
                                    <li><a href="/produtos">Produtos</a></li>
                                    <li><a href="/blog">Notícias</a></li>
                                    <li><a href="/areasocial">Onde Encontrar</a></li>
                                    <li><a href="/orcamento">Orçamento</a></li>
                                    <li><a href="/faleconosco">Fale conosco</a></li>
                                </ul>

                                <ul style={{ listStyle: 'none', color: '#e9e5e5' }}><b>Produtos</b>
                                    {this.state.products?.map((p, i) => (
                                        <>
                                            {i === 0 ?
                                                <li style={{ paddingTop: '0.8rem' }}><a href={`/produtos#${p?.slug}`}>{p?.title}</a></li>
                                                :
                                                <li><a href={`/produtos#${p?.slug}`}>{p?.title}</a></li>
                                            }
                                        </>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ paddingLeft: '2.5rem', color: '#e9e5e5', fontWeight: 'bold' }} >
                                <p style={{ fontSize: '1.1rem' }}><FaPhoneAlt style={{ marginRight: '0.5rem', color: '#ffffff' }} />(46) 3524-6318 / (46) 3524-7484</p>

                                <p style={{ fontSize: '0.92rem' }}>Endereço: Rua Vereador Romeu Lauro Werlang, <br />450-Centro.Francisco Beltrão-Pr, 85.601-020</p>

                                <p style={{ fontSize: '0.92rem' }}><AiOutlineMail style={{ marginRight: '0.5rem', color: '#ffffff' }} />contato@stangdistribuidora.com.br</p>

                                <p>Segunda à sexta: 08h às 12h / 13:30h às 17:30</p>
                                <p style={{ lineHeight: '0' }}>Sábado: 08h às 12h</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: '2rem' }}>
                    <span style={{ color: 'white', fontSize: '0.9rem' }}>
                        Copyright © 2021, Todos os direitos reservados Stang e Camaleon360
                        <br /><br />
                        <span style={{ fontSize: '0.8rem' }}>
                            Desenvolvido por Camaleon MKT e <a style={{ color: '#00365A' }} rel="noreferrer" href="http://techraptors.com.br" target="_blank">TECHRAPTORS</a>
                        </span>
                    </span>
                </div>
                <br />
            </div>
        );
    }
}