import { Header } from "../../header/Header";
import Footer from "../../components/footer/Footer";
import { Container, Form, Button, Col, Row, Modal } from "react-bootstrap";
import Animate from 'react-smooth';
import posto from '../../images/postofranqueado.png';
import './SejaFranqueado.css';
import { useState } from "react";

export function SejaFranqueado() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Animate to="1" from="0" attributeName="opacity">
            <Header />
            <Container style={{ paddingTop: '3rem', fontSize: '0.9rem' }}>
                <Row className="sejafranqueado">
                    <h1 style={{ color: '#143f86', fontWeight: 'bold', marginBottom: '3rem' }}>Seja um franqueado</h1>
                    <img src={posto} style={{ width: '100%', borderRadius: '100px 0px 0px 0px', objectFit: 'cover', objectPosition: 'bottom', height: '100%' }} />
                    <Col xs lg="5" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <p style={{ color: 'white', marginTop: '5rem', fontSize: '1.8rem', fontWeight: 'bold' }}>Estamos em constante processo de expansão</p>
                    </Col>
                    <Row className="justify-content-md-center" style={{ paddingBottom: '2rem' }}>
                        <Col xs lg="6" style={{ display: 'flex', justifyContent: 'center', textAlign: 'justify' }}>
                            <p style={{ color: 'white' }}>
                                Nossos postos estão localizados nos estados do Paraná e Santa Catarina,
                                e contam com uma estrutura ampla e segura, oferecendo os serviços
                                tradicionais como, conveniência, casa do motorista, restaurantes e outros.
                            </p>
                        </Col>
                    </Row>
                </Row>
                <Row style={{ marginTop: '-3.5rem', display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: '3rem' }}>
                    <Button style={{ backgroundColor: '#143f86', border: 'none', padding: '0.8rem 3rem 0.8rem 3rem', borderRadius: '10px' }} onClick={handleShow}>
                        Quero ser um franqueado
                    </Button>
                </Row>
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton >
                        <Modal.Title style={{ fontSize: '1.2rem' }}>Informe os dados abaixo e clique em enviar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ fontSize: '0.8rem' }}>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridNomeDaEmpresa">
                                        <Form.Label>Nome da Empresa</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>

                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridCnpj">
                                        <Form.Label>CNPJ</Form.Label>
                                        <Form.Control type="text" placeholder="cnpj" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridNomeResponsavel">
                                        <Form.Label>Nome do Responsável</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Cidade</Form.Label>
                                        <Form.Control type="text" placeholder="cpf" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control type="text" placeholder="cpf" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="cpf" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Row className=" mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Button variant="danger" className="w-100" onClick={handleClose} style={{ fontSize: '0.8rem', textTransform: 'uppercase', marginLeft: '2rem', marginRight: '2rem' }}>
                                Enviar
                            </Button>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
            <div >
                <Footer />
            </div>
        </Animate>
    );
}
