import { Button, Container, Nav, Navbar, Row, NavDropdown } from "react-bootstrap";
import './Header.css';
import logoImg from '../images/LOGO_STANG.svg';
import iconewhats from '../images/iconewhats.svg';

export function Header() {
    return (
        <>
            {/* fixed="top" */}
            <Navbar expand="lg" style={{ backgroundColor: '#00365A', borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem', color: '#fff' }} fixed="top" variant="dark" >
                <Container style={{ marginTop: '1rem' }}>
                    <Navbar.Brand className="brand" href="/">
                        <img src={logoImg} alt="Stang" className="logoimg" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Container className="container-menu" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>

                        {/*className="btn-header" <div style={{ display: 'flex', textAlign: 'right', justifyContent: 'right' }}>
                                <a href="https://stang.sispetro.com.br/login" target="blank" style={{ textDecoration: 'none' }}>
                                    <Button size="sm" style={{ paddingLeft: '1rem', fontSize: '13px' }}>Portal do cliente</Button>
                                </a>
                            </div> */}
                        <div className="w-100 btn-header" style={{ justifyContent: 'right', textAlign: 'right', marginRight: '4rem' }}>
                            <a href="home#complience" style={{ textDecoration: 'none' }}>
                                <Button size="sm" style={{ paddingLeft: '1rem', paddingRight: '1.3rem', fontSize: '13px', marginRight: '1.3rem', border: 'none', backgroundColor: '#fea50e'  }}>
                                    Conformidade
                                </Button>
                            </a>
                            <a href="https://app.sispetro.com.br/?host=https://stang.sispetro.com.br/" target="blank" style={{ textDecoration: 'none' }}>
                                <Button size="sm" style={{ paddingLeft: '1rem', paddingRight: '1.3rem', fontSize: '13px', border: 'none'  }}>
                                    Portal do cliente
                                </Button>
                            </a>
                            <a  href="https://web.whatsapp.com/send?phone=554699261098" target="blank" style={{ textDecoration: 'none'}}>
                                <Button size="sm" style={{ paddingLeft: '1rem', paddingRight: '1.3rem', fontSize: '13px', marginLeft: '1.3rem', backgroundColor: '#1dc407', border: 'none' }}>
                                    <div style={{display: 'flex', justifyContent: 'center' }}>
                                    <img src={iconewhats} style={{maxWidth: '15px', marginRight: '4px'}}/>WhatsApp
                                    </div>
                                </Button>
                            </a>
                        </div>

                        <Row>

                            <hr className="hr-menu" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Nav.Link href="/sobre" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Sobre a Empresa </Nav.Link>
                                    <div className="separator">  </div>
                                    <Nav.Link href="/produtos" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Produtos</Nav.Link>
                                    <div className="separator">  </div>
                                    <Nav.Link href="/sejafranqueado" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Franquia</Nav.Link>
                                    <div className="separator">  </div>
                                    <Nav.Link href="/blog" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Blog</Nav.Link>
                                    <div className="separator">  </div>
                                    <Nav.Link href="/areasocial" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Área Social</Nav.Link>
                                    <div className="separator">  </div>
                                    <Nav.Link href="/ondeencontrar" style={{ color: 'white', fontSize: '14px' }} className="link-hover">Onde Encontrar</Nav.Link>
                                    <div className="separator">  </div>
                                    <NavDropdown title="Contato" id="basic-nav-dropdown" className="link-hover" style={{ color: '#fff', fontSize: '14px' }} >
                                        <NavDropdown.Item href="/faleconosco" style ={{fontSize: '13px'}}>Fale conosco</NavDropdown.Item>
                                        <NavDropdown.Item href="/denuncia" style ={{fontSize: '13px'}}>Denuncie</NavDropdown.Item>
                                    </NavDropdown>
                                    {/* <Nav.Link href="#link" >Contato</Nav.Link> */}
                                </Nav>
                                <a href="home#complience" style={{ textDecoration: 'none' }}>
                                    <Button  className="mobile" style={{marginBottom: '0.5rem', paddingLeft: '2rem', paddingRight: '2rem', fontSize: '13px', height: '2.5rem', marginRight: '1.3rem', border: 'none', backgroundColor: '#fea50e'  }}>
                                        Conformidade
                                    </Button>
                                </a>
                                <a href="https://app.sispetro.com.br/?host=https://stang.sispetro.com.br/" target="blank">
                                    <Button className="mobile" style={{ marginBottom: '0.5rem', paddingLeft: '1rem', height: '2.5rem',paddingRight: '1.3rem' }} >Portal do cliente</Button>
                                </a>
                                <a href="https://web.whatsapp.com/send?phone=554699261098" target="_blank" style={{ textDecoration: 'none'}}>
                                
                                    <Button className="mobile" style={{ marginBottom: '0.5rem', paddingLeft: '2.7rem', paddingRight: '2.7rem', fontSize: '13px', height: '2.5rem', marginRight: '1.3rem',  backgroundColor: '#1dc407', border: 'none' }}>
                                        <div style={{display: 'flex', justifyContent: 'center' }}>
                                        <img src={iconewhats} style={{maxWidth: '15px', marginRight: '4px'}}/>WhatsApp
                                        </div>
                                    </Button>
                                </a>

                            </Navbar.Collapse>
                        </Row>
                    </Container>
                </Container>
            </Navbar>
        </>
    );
}
