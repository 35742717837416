import { Container, Row } from "react-bootstrap";
import { Layout } from "../../interfaces/layout";
import { Combustivel } from "../combustivelverde/Combustivel";
import './Banner.css';
import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import { API_URL, axiosconfig } from "../../core";

export function Banner() {
    const [layout, setLayout] = useState<Layout>();
    useEffect(() => {
        axios.get(API_URL + '/api/layout?format=json', axiosconfig)
        .then((response: AxiosResponse) => {
            setLayout( response.data );
        });       
    }, []);
    return (
        <>
            {/* paddingTop: '7.2rem',  */}
            <Container fluid style={{margin: '0', position: 'relative', border: 'none' }}>
                <Row>
                    {layout?.bannerHome ?
                    <video loop autoPlay muted style={{ display: 'block', width: '100%', borderBottom: '0px solid #1A76D9' }}>
                        <source src={`${API_URL}${layout?.bannerHome}`} type="video/mp4" />
                    </video>
                    : 
                    <div></div>
                    }                   
                    <h1 className="texto animate__animated animate__bounceIn" style={{maxWidth: '100%'}}>{layout?.title}</h1>
                </Row>
            </Container>
            <Container fluid className="position-absolute">
                <Row>
                    <Combustivel />
                </Row>
            </Container>
        </>
    );
}