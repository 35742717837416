import { Button, Card, CardGroup, Col, Container, Image, Row, Carousel } from "react-bootstrap";
import { CardNews } from "./CardNews";
import './Sobre.css';
import Footer from "../footer/Footer";
import { useEffect, useState } from "react";
import { API_URL, axiosconfig } from "../../core";
import axios, { AxiosResponse } from "axios";
import { About } from "../../interfaces/about";
import { AboutSlide } from "../../interfaces/aboutSlide";
import { Pub } from "../../interfaces/pub";
import logoImg from '../../images/LOGO_STANG.svg';
import caminhaorodoarla from '../../images/sobre/caminhaorodoarla.png';
import logorodoarla from '../../images/sobre/logorodoarla.svg';
import trrImage from '../../images/trr/trr.png';


import { Code } from "../../interfaces/code";

export function Sobre() {
    const [about, setAbout] = useState<About>();
    const [code, setCode] = useState<Code>();
    const [aboutSlides, setAboutSlides] = useState<AboutSlide[]>([]);
    const [pubs, setPubs] = useState<Pub>();
    useEffect(() => {
        axios.get(API_URL + '/api/about?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAbout(response.data);
            });
        axios.get(API_URL + '/api/aboutSlides?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAboutSlides(response.data);
            });
        axios.get(API_URL + '/api/pubs?page_size=3&format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setPubs(response.data);
            });
        axios.get(API_URL + '/api/code?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setCode(response.data);
            });
    }, []);
    return (
        <>
            <div  className="position-relative">
                <Carousel style={{ margin: '0' }}>
                    {aboutSlides.map(slide => (
                        <Carousel.Item key={slide.image}>
                            <Image src={`${API_URL}/${slide.image}`} fluid />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <div className="fundo-sobre">
                </div>
                <div className="position-absolute card-sobre">
                    <Card className="width-card">
                        <Card.Body>
                            <Card.Title style={{ color: "#084091", fontWeight: "bold", fontSize: "2rem" }}>Sobre Nós</Card.Title>
                            <Card.Text>
                                {about?.content}
                            </Card.Text>
                            <Button variant="danger" className="btn-saibamais-sobre">Saiba Mais</Button>
                        </Card.Body>
                    </Card>
                </div>

                <Row className="w-100 position-absolute news">
                    <Col md={12}>
                        <div className="wrapper-news">
                            <div className="text-follow">
                                <h3 className="text-follow" >
                                    Últimas Notícias
                                </h3>
                            </div>
                            <div className="text-news" >
                                <p>
                                    Acompanhe nossas novidades, as últimas e principais<br />
                                    notícias sobre Combustível. Entrevistas, análises, opinião e muito mais.
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Container style={{ display: "flex", justifyContent: "center", textAlign: "center" }} >
                        <Row>
                            {pubs?.results.map(pub => (
                                <CardNews {...pub} />
                            ))} 
                            <br />
                            <br />
                            <br />
                        </Row>

                    </Container>
                    <Container fluid style={{marginTop: '2rem'}}>
                        <Row >
                            <Col sm={4} style={{ backgroundColor: '#f33700', display: 'flex', alignItems: 'self-end', justifyContent: 'flex-end', borderRadius: '0 20px 20px 0', paddingTop: '3rem', paddingBottom: '3rem'}}>
                                <Col sm={4}>
                                    <img src={logorodoarla} style={{paddingBottom: '2rem'}} />
                                </Col>
                            </Col>
                            <Col sm={4} style={{backgroundColor: 'white', paddingLeft: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                                <Col sm>
                                    <div style={{padding: '2rem', alignItems: 'center'}}>
                                        <p style={{color: '#f33700', fontSize: '2rem', fontWeight: 'bold', lineHeight: '1rem'}} > 
                                            Parceria com </p>
                                            <p style={{color: '#f33700', fontSize: '2rem', fontWeight: 'bold', lineHeight: '1em'}} >  qualidade e </p>
                                            <p style={{color: '#f33700', fontSize: '2rem', fontWeight: 'bold', lineHeight: '1rem'}} >custo-benefício</p>
                                        <p style={{fontSize: '1rem'}}>Reduzimos a emissão de gás poluente emitido pelos veículos a diesel com tecnologia SCR (Selective Catalytic Reduction).</p>
                                        <a href="https://rodoarla.com.br/" target="blank" style={{ textDecoration: 'none' }}>
                                            <Button style={{backgroundColor: '#f33700', border: 'none', fontSize: '1.1rem', paddingLeft: '5rem', paddingRight: '5rem'}}>
                                                Saiba Mais
                                            </Button>
                                        </a>
                                    </div>
                                </Col>
                            </Col>
                            <Col sm={4} style={{padding: '0', display: 'flex', justifyContent: 'right'}}>
                                <img src={caminhaorodoarla} style={{ width: '90%',  borderRadius: '100px 0px 0px 0px', objectFit: 'cover', objectPosition: 'bottom', height: '100%'}} />
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid style={{marginTop: '2rem'}}>
                        <Row >
                            <Col sm={7} style={{backgroundColor: 'white', display: 'flex',  justifyContent: 'center'}}>
                                <Col sm={12} className="cartrr">
                                    <div >
                                        <img src={trrImage} className='w100'/>
                                    </div>
                                    <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', paddingTop: '3.2rem'}}>
                                        <p style={{color: '#43A307', fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem'}} > 
                                            Agilidade na</p>
                                        <p style={{color: '#43A307', fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem'}} > 
                                            entrega e</p>
                                        <p style={{color: '#43A307', fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem'}} >
                                            credibilidade</p>
                                        <p style={{color: '#43A307', fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem'}} >
                                            no combustível</p>
                                   
                                    </div>
                                </Col>
                            </Col>
                            <Col sm={5} style={{ backgroundColor: '#0e3d46', display: 'flex', alignItems: 'self-start', justifyContent: 'flex-start',
                                 borderRadius: '50px 0px 0px 0px', paddingTop: '3rem', paddingBottom: '3rem'}}>
                                <Col sm={6}>
                                <p style={{color: 'white', fontWeight: 'bold', fontSize: '1rem'}}>Fornecemos serviços de abastecimento de diesel 100%
                                    nacional e sem alterações para produtores rurais, transportadoras, indústrias com geradores e empresas de locomotivas.
                                </p>
                                <a href="https://www.facebook.com/trrdieselrural/" target="blank" style={{ textDecoration: 'none' }}>
                                    <Button style={{backgroundColor: '#9ece06', border: 'none', fontSize: '1rem', fontWeight: 'bold', paddingLeft: '2rem', paddingRight: '2rem'}}>
                                        Saiba Mais
                                    </Button>
                                </a>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    
                    <Container className="fundo-complience" fluid style={{ marginTop: '2rem'}} >
                        <Container>
                            <Card>
                                <CardGroup>
                                    <Card style={{ border: "none", paddingLeft: '2rem' }}>
                                        <iframe id="frame" width="300" title="frame" height="568" src="https://www.instagram.com/p/CQjcgKNppfg/embed" style={{ border: 'none' }} />
                                    </Card>
                                    <Card style={{ border: "none", textAlign: 'center' }}>
                                        <Card.Img className="img-news" src={logoImg} />
                                        <Card.Body>
                                            <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>CÓDIGO DE ÉTICA E CONDUTA</Card.Title>
                                            <Card.Text >
                                                {code?.content}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                                            <a href={`${API_URL}${code?.document}`} target="_blank" rel="noreferrer"><Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem", fontSize: '0.8rem' }}>Acesse aqui</Button></a>
                                        </Card.Footer>
                                    </Card>
                                    <Card style={{ border: "none", textAlign: 'center' }}>
                                        <p className="complience" >COMPLIANCE</p>
                                        <Card.Body>
                                            <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>DENUNCIE IRREGULARIDADES ATRAVÉS DO CANAL DE DENÚNCIA STANG</Card.Title>
                                            <Card.Text>
                                                Nossa plataforma de denúncia é operada por uma empresa externa especializada em captação de relatos de não
                                                conformidade. Os relatos podem ser realizados de forma anônima ou com identificação.
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                                            <a href="/denuncia"><Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem", fontSize: '0.8rem' }}>Denunciar</Button></a>
                                        </Card.Footer>
                                    </Card>
                                </CardGroup>
                            </Card>
                        </Container>
                    </Container>
                    <div className="w-100 footer-mob">
                        <Footer />
                    </div>
                </Row>
            </div>

        </>
    );
}