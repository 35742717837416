import { Container, Row, Col } from "react-bootstrap";
import { Combustivel } from "../../components/combustivelverde/Combustivel";
import Footer from "../../components/footer/Footer";
import { Header } from "../../header/Header";
import bannerImg from '../../images/areasocial/banner.jpg'
import { useEffect, useState } from "react";
import { API_URL, axiosconfig } from "../../core";
import axios, { AxiosResponse } from "axios"
import './AreaSocial.css';
import { SocialArea } from "../../interfaces/socialarea";
import Animate from 'react-smooth';

export function AreaSocial() {
    const [socialarea, setSocialArea] = useState<SocialArea>();
    useEffect(() => {
        axios.get(API_URL + '/api/socialarea?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setSocialArea(response.data);
            });
    }, []);
    return (
        <Animate to="1" from="0" attributeName="opacity">
            <Container fluid style={{ margin: '0', position: 'absolute', border: 'none' }}>
                <Header />
            </Container>
            <div>
                <Container fluid className="w-100 comb-verde" style={{ zIndex: 1, position: 'absolute' }} >
                    <Row>
                        <Combustivel />
                    </Row>
                </Container>
                <div className="divider"></div>
                <div style={{ backgroundImage: `url(${bannerImg})`, minHeight: '80vh', backgroundSize: 'cover', zIndex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Container>
                        <Row style={{ paddingTop: '5vh', color: 'white' }}>
                            <Col sm={10}>
                                <h1 style={{ fontSize: '6vh' }}>{socialarea?.title}</h1>
                                <span style={{ whiteSpace: 'pre-wrap', fontSize: '2vh' }}>
                                    {socialarea?.content}
                                </span>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
                <div style={{ backgroundColor: '#0A5191', height: '4vh', width: '50%', marginTop: '-4vh' }}></div>
                <div className="bg-fundo scont">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <img className="img-fluid simg" src={`${API_URL}${socialarea?.image}`} alt="Stang" />
                    </div>
                    <Row className="srow" >
                        <Col className="scol1"></Col>
                        <Col className="scol2"></Col>
                    </Row>
                </div>
                <div className="bg-fundo scontb">
                    <Container style={{ maxWidth: '60vw' }}>
                        <div style={{ height: '6vh' }}></div>
                        <Row className="srow">
                            <Col className="scol1b" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '5vh', fontWeight: 'bold', color: "white", textAlign: 'left' }}>
                                    Condições de<br />
                                    trabalho ao<br />
                                    motorista
                                </span>
                            </Col>
                            <Col className="scol2b" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '2vh', color: "white", textAlign: 'left' }}>
                                    {socialarea?.content2}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="mob">
                    <img style={{ width: '100%' }} src={`${API_URL}${socialarea?.image}`} alt="Stang" />
                    <br /><br />
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: '5vh', fontWeight: 'bold', color: "white", textAlign: 'left' }}>
                                Condições de<br />
                                trabalho ao<br />
                                motorista
                            </span>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: '2vh', color: "white", textAlign: 'left' }}>
                                {socialarea?.content2}
                            </span>
                        </Col>
                    </Row>
                </div>

            </div>
            <Footer />
        </Animate>
    );
}