const { REACT_APP_STANG_TOKEN } = process.env;

export const API_URL = 'https://stang.duocyber.com';
export const axiosconfig = {
    headers: { Authorization: REACT_APP_STANG_TOKEN }
};

export const axiosconfigwithfiles = {
    headers: { Authorization: REACT_APP_STANG_TOKEN, 'Content-Type': 'multipart/form-data' }
};
export const GOOGLE_SITE_KEY = '6LfTqWEbAAAAAFMJ8ofRx_GOWUXaeboEB7SEJBzq';
