import { Banner } from '../../components/banner/Banner';
import { Produtos } from '../../components/produtos/Produtos';
import { Sobre } from '../../components/sobre/Sobre';
import { Header } from '../../header/Header';
import Animate from 'react-smooth';
// import {useEffect, useState} from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import imgAlerta from '../../images/alerta.png';

export function Home(){
  // const [displayPopUp, setDisplayPopUp] = useState(true);

  // const closePopUp = () => {
  //   // setting key "seenPopUp" with value true into localStorage
  //   localStorage.setItem("seenPopUp", 'true');
  //   setDisplayPopUp(false);
  // };

  // useEffect(() => {
  //   // getting value of "seenPopUp" key from localStorage
  //   let returningUser = localStorage.getItem("seenPopUp");
  //   if(returningUser == 'true'){
  //       setDisplayPopUp(false);
  //   }
  // }, []);
    return(
        <div className="App">
            {/* <Modal show={displayPopUp}>
              <Modal.Body>
                  <img src={imgAlerta} alt="garantimos" className='w-100' />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopUp} style={{backgroundColor: '#42AAD8', border: 'none'}}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal> */}
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <Banner />
                <Produtos />
                <Sobre />
            </Animate>
       </div>
    )
}