import TextTruncate from 'react-text-truncate';
import { API_URL } from '../../core';
import { PubDetail } from '../../interfaces/pub';
import './Blog.css';
import Animate from 'react-smooth';

export function CardBlog(pub: PubDetail) {
    return (
        <Animate to="1" from="0" attributeName="opacity">
            <div className="col" style={{ paddingTop: '1rem' }}>
                <div className="card shadow-sm">
                    <img className="bd-placeholder-img card-img-top" width="100%" src={`${API_URL}${pub?.image}`} alt="" />
                    <div className="card-body">
                        <span className="title-new-blog">
                            <TextTruncate
                                line={2}
                                element="span"
                                truncateText="…"
                                text={pub?.title}
                                textTruncateChild={""}
                            />
                        </span>
                        <p className="content-new-blog">
                            <TextTruncate
                                line={5}
                                element="span"
                                truncateText="…"
                                text={pub?.content}
                                textTruncateChild={""}
                            />
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                                <a href={`/pub/${pub.slug}`}><button type="button" className="btn btn-sm btn-outline-secondary btn-card-blog">ver mais</button></a>
                            </div>
                            <small className="text-muted">{pub?.pub}</small>
                        </div>
                    </div>
                </div>
            </div>
        </Animate>
    );
}