import { Header } from "../../header/Header";
import bannerImg from '../../images/ondeencontrar/bannerondeencontrar.png'
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import './OndeEncontrar.css';
import Footer from "../../components/footer/Footer";
import axios from 'axios';
import { API_URL, axiosconfig } from "../../core";
import React from "react";
import Mapa from "./Mapa";
import Animate from 'react-smooth';

export default class OndeEncontrar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            fuelStations: [],
            provincesAndCities: [],
            provinces: [],
            selectedProvince: 'ESTADO',
            selectedCity: 'CIDADE'
        };
        this.breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
            { width: 850, itemsToShow: 5 }
        ]
    }
    componentDidMount() {
        let url = API_URL + '/api/fuelStations?format=json'
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ fuelStations: res.data });
            })
        let url2 = API_URL + '/api/provincesAndCities?format=json'
        axios.get(url2, axiosconfig)
            .then(res => {
                this.setState({ provincesAndCities: res.data });
            })            
    }
    getProvinces() {
        let provinces = []
        this.state.provincesAndCities.forEach(function (item) {
            provinces.push(item.province)
        })
        return Array.from(new Set(provinces));
    }

    setProvince(prov) {
        this.setState({ selectedProvince: prov, selectedCity: 'CIDADE' });
    }

    setCity(cit) {
        this.setState({ selectedCity: cit });
    }

    getCities() {
        return this.state.provincesAndCities.filter((item, index) => item.province === this.state.selectedProvince);
    }

    getFuelStations() {
        let filtered = [];
        let selectedProvince = this.state.selectedProvince;
        this.state.fuelStations.forEach(function (item){
            if (selectedProvince !== 'ESTADO'){
                if (item.province === selectedProvince){
                    filtered.push(item);
                }
            }
        });
        let finalList = [];
        let selectedCity = this.state.selectedCity;
        filtered.forEach(function (item){
            if (selectedCity !== 'CIDADE'){
                if (item.city === selectedCity){
                    finalList.push(item);
                }
            }
        })
        return finalList;
    }
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <div className="wrapperoe">
                    <div className="divimageoe" style={{ backgroundImage: `url(${bannerImg})` }}></div>
                    <div className="divtext" data-aos="fade-left"><b>Onde encontrar</b></div>
                </div>
    
                <div className="col-lg-12" style={{ textAlign: 'center', color: '#00365A', fontWeight: 'bold' }}>
                    <h3 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Localize a Stang mais próxima de você</h3>
                </div>
                <Container>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control as="select" onChange={(event) => { this.setProvince(event.target.value) }}>
                                <option>ESTADO</option>
                                {this.getProvinces().map((item, i) =>
                                    <option>{item}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
    
                        <Form.Group as={Col} controlId="formGridZip" onChange={(event) => { this.setCity(event.target.value) }}>
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control as="select" defaultValue="Choose...">
                                <option>CIDADE</option>
                                {this.getCities().map((item, i) =>
                                    <option>{item.city}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                </Container>
                <Container style={{ paddingTop: '2rem' }}>
                    <Row>                        
                        <Col sm={5}>
                            {this.getFuelStations().map((item, i) =>
                            <div style={{paddingBottom: '2rem'}}>
                                <h4>Posto Delta</h4>
                                <span>{item.address} - {item.city}-{item.province}</span>
                                <p>Telefone: {item.phone}</p>
                                <a href={`https://maps.google.com?q=${item.latitude}+${item.longitude}`} target={'_blank'} rel="noreferrer">
                                    <Button>Ver no maps</Button>
                                </a>                                
                            </div>
                            )}
                        </Col>
                        <Col sm={7}>
                            <Mapa fuelStations={this.state.fuelStations} />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </Animate>
        );
    }

}