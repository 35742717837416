import { Header } from "../../header/Header";
import Footer from "../../components/footer/Footer";
import { Container, Form, Button, Col, Row, Modal } from "react-bootstrap";
import Animate from 'react-smooth';
import posto from '../../images/posto.png';
import { useState } from "react";

export function ProprioPosto() {

    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Animate to="1" from="0" attributeName="opacity">
            <Header />
            <Container style={{ paddingTop: '3rem', fontSize: '0.9rem' }}>
                <Row style={{ paddingTop: '3rem', alignItems: 'center' }}>
                    <Col sm={6} style={{ paddingLeft: '5rem' }}>
                        <h2 style={{ color: '#143f86', textTransform: 'uppercase', fontWeight: 'bold', paddingTop: '4rem', fontFamily: 'Montserrat' }}>
                            Tenha seu
                        </h2>
                        <h2 style={{ color: '#143f86', textTransform: 'uppercase', fontWeight: 'bold', lineHeight: '1rem' }}>
                            próprio ponto de
                        </h2>
                        <h2 style={{ color: '#143f86', textTransform: 'uppercase', fontWeight: 'bold' }}>
                            abastecimento
                        </h2>
                        <p style={{ fontSize: '1rem', textAlign: 'left' }}>
                            Empresa, cooperativa e indústria podem ter
                            seu próprio posto de abastecimento. A Stang
                            disponibiliza toda a estrutura com a tecnologia e
                            expertise de seus produtos para os nossos clientes,
                            facilitando o abastecimento de sua frota.
                        </p>
                        <Button style={{
                            backgroundColor: '#143f86', fontWeight: 'bold',
                            border: 'none', width: '100%', borderRadius: '15px', minHeight: '70px', marginTop: '2rem'
                        }} onClick={handleShow}>
                            Tenho interesse
                        </Button>
                    </Col>
                    <Col sm={6}>
                        <img src={posto} className="w-100" />
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton >
                        <Modal.Title style={{ fontSize: '1.2rem' }}>Informe os dados abaixo e clique em enviar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ fontSize: '0.8rem' }}>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridNome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>

                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridCpf">
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control type="text" placeholder="cpf" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="email" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridEstado">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridCidade">
                                        <Form.Label>Cidade</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ margin: '0rem', lineHeight: '0.5rem' }}>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridAreaAtuacao">
                                        <Form.Label>Área de atuação</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                                <Col sm style={{ margin: '0px', padding: '0px' }}>
                                    <Form.Group as={Col} controlId="formGridTelefone">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Row className=" mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Button variant="danger" className="w-100" onClick={handleClose} style={{ fontSize: '0.8rem', textTransform: 'uppercase', marginLeft: '2rem', marginRight: '2rem' }}>
                                Enviar
                            </Button>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
            <div style={{ marginTop: '-0.3rem' }}>
                <Footer />
            </div>
        </Animate>
    );
}