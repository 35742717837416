import { Header } from "../../header/Header";
import Footer from "../../components/footer/Footer";
import { Container, Form, Button, Col } from "react-bootstrap";
import axios from 'axios';
import { API_URL, axiosconfigwithfiles, GOOGLE_SITE_KEY } from "../../core";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

export default class Denuncia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            address: '',
            city: '',
            kind: '',
            content: '',
            sent: false,
            err: false,
            document: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onFileChange = event => {
        this.setState({ document: event.target.files[0] });
    };

    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('address', this.state.address);
        data.append('city', this.state.city);
        data.append('kind', this.state.kind);
        data.append('content', this.state.content);
        if (this.state.document !== null) {
            data.append('document', this.state.document);
        }
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/pronun`, data, axiosconfigwithfiles)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <Header />
                <Container style={{ paddingTop: '8rem', fontSize: '0.9rem' }}>
                    <h3 style={{ color: '#a8a7a7', paddingBottom: '2rem' }}>Denúncia – Elogio – Reclamação</h3>
                    {!this.state.sent ? <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Nome *</Form.Label>
                                <Form.Control required type="text" placeholder="" onChange={(e) => this.setState({ name: e.target.value })} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formGridAddress1">
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control placeholder="" onChange={(e) => this.setState({ address: e.target.value })} />
                        </Form.Group>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control onChange={(e) => this.setState({ city: e.target.value })} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control as="select" defaultValue="Denúncia" onChange={(e) => this.setState({ kind: e.target.value })}>
                                    <option value="Denúncia">Denúncia</option>
                                    <option value="Elogio">Elogio</option>
                                    <option value="Reclamação">Reclamação</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formGridMensagem">
                            <Form.Label>Mensagem *</Form.Label>
                            <Form.Control onChange={(e) => this.setState({ content: e.target.value })} required as="textarea" rows={3} placeholder="" />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Arquivo (caso necessário) </Form.Label>
                            <Form.File
                                onChange={this.onFileChange}
                                type="file"
                                className="position-relative"
                                name="file"
                                id="validationFormik107"
                                feedbackTooltip
                            />
                        </Form.Group>
                        <Form.Group>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={GOOGLE_SITE_KEY}
                            />
                        </Form.Group>
                        {this.state.err ? <div><h6 style={{ color: "red" }}>Você deve marcar o captcha</h6></div> : null}
                        <br />
                        <Button style={{ backgroundColor: "#ff2927", border: 'none', fontSize: '0.8rem', paddingLeft: '2rem', paddingRight: '2rem' }} type="submit">
                            Enviar
                        </Button>
                    </Form>
                        :
                        <div>
                            <h6 style={{ color: "green" }}>Obrigado pelo seu posicionamento!</h6>
                            <a href="/denuncia"><span style={{ color: 'blue' }}>repetir</span></a>
                        </div>
                    }
                </Container>
                <div style={{ paddingTop: '5rem' }}>
                    <Footer />
                </div>
            </Animate>
        );
    }
}