import './SobreNos.css';
import { Header } from '../../header/Header';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import { AboutItem } from "../../interfaces/aboutItem";
import { useEffect, useState } from "react";
import { API_URL, axiosconfig } from "../../core";
import axios, { AxiosResponse } from "axios"
import 'react-gallery-carousel/dist/index.css';
import { About } from '../../interfaces/about';
import { AboutSlide } from '../../interfaces/aboutSlide';
import { AboutGallery } from '../../interfaces/aboutGallery';
import Animate from 'react-smooth';
import imgBanner from '../../images/sobre/new/banner.jpg';
import imgIcones from '../../images/sobre/new/icones.png';
import imgIconRespeito from '../../images/sobre/new/icons/ICON_RESPEITO.svg';
import imgIconConfianca from '../../images/sobre/new/icons/ICON_CONFIANCA.svg';
import imgIconHonestidade from '../../images/sobre/new/icons/ICON_HONESTIDADE.svg';
import imgIconSustentabilidade from '../../images/sobre/new/icons/ICON_SUSTENTABILIDADE.svg';
import imgIconMelhoraContinua from '../../images/sobre/new/icons/ICON_MELHORA_CONTINUA.svg';
import imgIconExcelencia from '../../images/sobre/new/icons/ICON_EXCELENCIA.svg';
import imgIconTranparencia from '../../images/sobre/new/icons/ICON_TRANSPARENCIA.svg';
import imgIconEtica from '../../images/sobre/new/icons/ICON_ETICA.svg';
import imgIconIdoneidade from '../../images/sobre/new/icons/ICON_IDONEIDADE.svg';
import imgIconInovacao from '../../images/sobre/new/icons/ICON_INOVACAO.svg';
import imgGarantimos from '../../images/sobre/new/imgGarantimos.png';
import imgLogo from '../../images/sobre/new/logo.png';
import imgUmuarama1 from '../../images/sobre/new/umuarama1.png';
import imgUmuarama2 from '../../images/sobre/new/umuarama2.png';
import imgAraucaria1 from '../../images/sobre/new/araucaria1.jpg';
import imgAraucaria2 from '../../images/sobre/new/araucaria2.jpg';
import imgLondrina1 from '../../images/sobre/new/londrina1.jpg';
import imgLondrina2 from '../../images/sobre/new/londrina2.jpg';
import imgItajai1 from '../../images/sobre/new/itajai1.jpg';
import imgItajai2 from '../../images/sobre/new/itajai2.jpg';
import imgMapa from '../../images/sobre/new/mapa.png';
import imgcaminhao1 from '../../images/sobre/new/caminhoes-01.png';
import imgCaminhao2 from '../../images/sobre/new/caminhao2.jpg';
import imgCaminhao3 from '../../images/sobre/new/caminhao3.jpg';
import imgCaminhao4 from '../../images/sobre/new/caminhao4.jpg';
import imgCaminhao5 from '../../images/sobre/new/caminhao5.jpg';


export function SobreNos() {
    const [aboutItems, setAboutItems] = useState<AboutItem[]>([]);
    const [about, setAbout] = useState<About>();
    const [aboutSlides, setAboutSlides] = useState<AboutSlide[]>([]);
    const [aboutGallery, setAboutGallery] = useState<AboutGallery[]>([]);
    useEffect(() => {
        axios.get(API_URL + '/api/aboutItems?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAboutItems(response.data);
            });
        axios.get(API_URL + '/api/about?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAbout(response.data);
            });
        axios.get(API_URL + '/api/aboutSlides?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAboutSlides(response.data);
            });
        axios.get(API_URL + '/api/aboutGallery?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setAboutGallery(response.data);
            });
    }, []);
    return (
        <Animate to="1" from="0" attributeName="opacity">
            <Header />
            <Container fluid style={{ margin: '0', position: 'relative', border: 'none' }}>
                <Row>
                    {/* {layout?.bannerHome ?
                    <video loop autoPlay muted style={{ display: 'block', width: '100%', borderBottom: '0px solid #1A76D9' }}>
                        <source src={`${API_URL}${layout?.bannerHome}`} type="video/mp4" />
                    </video> */}
                    <img src={imgBanner} alt="banner" className="w-100" />
                    :
                    <div></div>
                    {/* }                    */}
                    <h1 className="texto animate__animated animate__bounceIn" style={{ maxWidth: '100%' }}>{ }</h1>
                    <Col md={{ span: 8, offset: 4 }} style={{
                        backgroundColor: '#005990',
                        display: 'flex', marginTop: '-4rem', justifyContent: 'left', alignItems: 'center'
                    }}>
                        <p style={{
                            textTransform: 'uppercase', fontWeight: 'bold', fontSize: '2.5rem',
                            marginTop: '1rem', color: '#5fc8db', marginLeft: '4rem'
                        }}>
                            olá, somos <span style={{ color: 'white' }}>a stang</span></p>
                    </Col>
                </Row>
                <Container style={{marginTop: '1rem'}} className='valores-full'>
                    <Row style={{display:'flex', justifyContent:'center', alignItems: 'center', textAlign:'center'}}>
                        <Col sm={4} style={{ display: 'block', float: 'right' }}>
                                <h2 style={{ color: '#084091', fontWeight: 'bold', marginLeft: '20%' }}>Nossos valores consolidados:</h2>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconRespeito} alt="icones" />
                            <p className='text-item'>Respeito</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconConfianca} alt="icones" className="w-100" />
                            <p className='text-item'>Confiança</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconHonestidade} alt="icones"  />
                            <p className='text-item'>Honestidade</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconSustentabilidade} alt="icones" className="w-100" />
                            <p className='text-item'>Sustentabilidade</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconMelhoraContinua} alt="icones" className="w-100" />
                            <p className='text-item'>Melhora contínua</p>
                        </Col>
                    </Row>
                    <Row style={{display:'flex', justifyContent:'center', alignItems: 'center', textAlign:'center', marginTop: '0.4rem'}}>
                        <Col sm={4} style={{ display: 'block', float: 'right' }}>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconExcelencia} alt="icones" className="w-100" />
                            <p style={{color:'#084091', fontSize: '11px', fontWeight: 'bold'}}>Excelência em produtos e serviços</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconTranparencia} alt="icones" className="w-100" />
                            <p style={{color:'#084091', fontSize: '11px', fontWeight: 'bold'}}>Transparência</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconEtica} alt="icones" className="w-100" />
                            <p style={{color:'#084091', fontSize: '11px', fontWeight: 'bold'}}>Ética</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconIdoneidade} alt="icones" className="w-100" />
                            <p style={{color:'#084091', fontSize: '11px', fontWeight: 'bold'}}>Idoneidade</p>
                        </Col>
                        <Col sm className='valores' style={{border: '1px solid #4399a8', borderRadius: '10px', marginRight: '5px', minHeight: '170px'}}>
                            <img src={imgIconInovacao} alt="icones" className="w-100" />
                            <p style={{color:'#084091', fontSize: '11px', fontWeight: 'bold'}}>Inovação</p>
                        </Col>
                    </Row>
                </Container>
                <Container style={{marginTop: '1rem'}} className='valores-mob'>
                        <Col sm>
                            <h2 style={{ color: '#084091', fontWeight: 'bold', marginLeft: '20%' }}>Nossos valores consolidados:</h2>
                        </Col>
                        <Col sm>
                            <img src={imgIcones} alt="icones" className="w-100" />
                        </Col>
                </Container>
                <Row style={{ backgroundImage: 'linear-gradient(to right, #6fc9de 40%, white 25%)', marginTop: '3rem', paddingBottom: '6rem' }}>
                    <Row className="w-100">
                        <Col sm style={{ display: 'flex', justifyContent: 'right', textAlign: 'right', marginTop: '-1rem' }}>
                            <Col xs lg="8">
                                <img src={imgGarantimos} alt="garantimos" className='w-100' />
                            </Col>
                        </Col>
                        <Col sm className='cardgarantimos'>
                            <h2 style={{ color: '#084091', fontWeight: 'bold' }}>
                                Garantimos agilidade e
                            </h2>
                            <h2 style={{ color: '#084091', fontWeight: 'bold' }}>
                                flexibilidade comercial
                            </h2>

                            <p style={{ lineHeight: '0.8rem', marginTop: '2rem' }}>A Stang iniciou as operações em 2011 no município</p>
                            <p style={{ lineHeight: '0.8rem' }}>de Araucária (PR), se desenvolveu com o propósito</p>
                            <p style={{ lineHeight: '0.8rem' }}>de superar as expectativas dos clientes, fornecendo</p>
                            <p style={{ lineHeight: '0.8rem' }}>produtos de alta qualidade, de forma rentável, segura,</p>
                            <p style={{ lineHeight: '0.8rem' }}>implicando no cuidado e respeito ao meio ambiente.</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '4rem' }} className="w-100 ">
                        <Col sm className='logoestruturaimpar' >
                            <Col sm xs lg="12">
                                <img src={imgLogo} alt="logo" style={{ width: '50%' }} />
                            </Col>
                        </Col>
                        <Col sm style={{ marginTop: '1rem' }} className='cardgarantimos'>
                            <h2 style={{ color: '#084091', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                Apresentamos uma
                            </h2>
                            <h2 style={{ color: '#084091', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                estrutura ímpar
                            </h2>
                            <p>
                                As bases STANG são totalmente automatizadas, garantindo agilidade, e precisão
                                nos processos de carga e descarga.
                                Contamos com uma tecnologia de ponta, com os mais rigorosos sistemas de controle:
                            </p>
                            <p> * Sistema automático de carga e descarga;</p>
                            <p> * Sistema ambiental que opera 24h por dia contra vazamentos e contaminação;</p>
                            <p> * Sistema de prevenção e combate a incêndio;</p>
                            <p> * Sensores de qualidade que examinam o produto em tempo real;</p>
                            <p> * Sistema de válvulas de alívio de pressão que evitam o colapso dos tanques;</p>
                            <p> * Sensores que medem com precisão o volume dos produtos armazenados.</p>
                        </Col>
                    </Row>
                </Row>
                <Container style={{ marginTop: '-3rem' }}>
                    <Row>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgUmuarama1} alt="umuarama1" className="w-100" />
                            <p style={{
                                backgroundColor: '#6fc9de', color: 'white', minWidth: '150px',
                                marginTop: '-4rem', marginLeft: '-1rem', position: 'absolute',
                                padding: '0.5rem 1rem 0.5rem 1rem', fontSize: '1.2rem', fontWeight: 'bold'
                            }}>
                                Umuarama
                            </p>
                        </Col>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgUmuarama2} alt="umuarama2" className="w-100" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgAraucaria1} alt="araucaria" className="w-100" />
                            <p style={{
                                backgroundColor: '#6fc9de', color: 'white', minWidth: '150px',
                                marginTop: '-4rem', marginLeft: '-1rem', position: 'absolute',
                                padding: '0.5rem 1rem 0.5rem 1rem', fontSize: '1.2rem', fontWeight: 'bold'
                            }}>
                                Araucária
                            </p>
                        </Col>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgAraucaria2} alt="araucaria2" className="w-100" />
                        </Col>
                    </Row>
                </Container>
                
                <Container style={{ marginTop: '-0.2rem' }}>
                    <Row>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgLondrina1} alt="londrina1" className="w-100" />
                            <p style={{
                                backgroundColor: '#6fc9de', color: 'white', minWidth: '150px',
                                marginTop: '-4rem', marginLeft: '-1rem', position: 'absolute',
                                padding: '0.5rem 1rem 0.5rem 1rem', fontSize: '1.2rem', fontWeight: 'bold'
                            }}>
                                Londrina
                            </p>
                        </Col>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgLondrina2} alt="londrina2" className="w-100" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgItajai1} alt="itajai1" className="w-100" />
                            <p style={{
                                backgroundColor: '#6fc9de', color: 'white', minWidth: '150px',
                                marginTop: '-4rem', marginLeft: '-1rem', position: 'absolute',
                                padding: '0.5rem 1rem 0.5rem 1rem', fontSize: '1.2rem', fontWeight: 'bold'
                            }}>
                                Itajaí
                            </p>
                        </Col>
                        <Col sm style={{ padding: '3px' }}>
                            <img src={imgItajai2} alt="itajai2" className="w-100" />
                        </Col>
                    </Row>
                </Container>
                <Row className='colorfrotapropria'>
                    <Container>
                        <Row style={{ marginTop: '15rem' }}>
                            <Col sm={5} style={{ paddingTop: '5rem' }}>
                                <h1 style={{ textTransform: 'uppercase', color: '#084091', fontWeight: 'bold' }}>frota própria</h1>
                                <p style={{ color: 'white', lineHeight: '0.8rem', marginTop: '2rem' }}>Somos pioneiros no Brasil em aderir a</p>
                                <p style={{ color: 'white', lineHeight: '0.8rem' }}>câmera de segurança nos caminhões.</p>
                                <p style={{ color: 'white', lineHeight: '0.8rem' }}>Monitorados 24h por satélites, nossa frota</p>
                                <p style={{ color: 'white', lineHeight: '0.8rem' }}>transite em total segurança pelos estados</p>
                                <p style={{ color: 'white', lineHeight: '0.8rem' }}>de São Paulo, Mato Grosso do Sul, Paraná, </p>
                                <p style={{ color: 'white', lineHeight: '0.8rem' }}>Santa Catarina e Rio Grande do Sul.</p>
                            </Col>
                            <Col sm={7} style={{ padding: '0px', display: 'flex', textAlign: 'left' }}>
                                <img src={imgcaminhao1} alt="frota" className='w-100' />
                            </Col>
                        </Row>

                    </Container>
                </Row>

                <Row style={{
                    backgroundImage: 'linear-gradient(to left, #6fc9de 48%, white 25%)',
                    marginTop: '-13rem'
                }}>
                    <Container>
                        <Row style={{ marginTop: '-7.2rem' }}>
                            <Col sm={6} style={{ padding: '0px' }}>
                                <img src={imgCaminhao2} alt="frota" className='w-100' />
                            </Col>
                            <Col sm={6} style={{ padding: '0px' }}>
                                <img src={imgCaminhao3} alt="frota" className='w-100' />
                            </Col>
                            <Col sm={6} style={{ padding: '0px' }}>
                                <img src={imgCaminhao4} alt="frota" className='w-100' />
                            </Col>
                            <Col sm={6} style={{ padding: '0px' }}>
                                <img src={imgCaminhao5} alt="frota" className='w-100' />
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{ background: 'linear-gradient(to left, #94c925 45%, white 25%)', marginTop: '-1.2rem', marginBottom:"2rem" }}>
                    <Col sm style={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                        <Col sm xs lg="12" style={{ paddingTop: '6rem' }}>
                            <div style={{ textAlign: 'left', float: 'right' }}>
                                <h2 style={{ color: '#084091', fontWeight: 'bold', textTransform: 'uppercase', lineHeight: '1.8rem' }}>
                                    uma grande
                                </h2>
                                <h2 style={{ color: '#084091', fontWeight: 'bold', textTransform: 'uppercase', lineHeight: '1.8rem' }}>
                                    estrutura de
                                </h2>
                                <h2 style={{ color: '#084091', fontWeight: 'bold', textTransform: 'uppercase', lineHeight: '1.8rem' }}>
                                    distribuição
                                </h2>
                                <p style={{ lineHeight: '1rem', marginTop: '2rem' }}>Atualmente, possuímos modernas bases</p>
                                <p style={{ lineHeight: '1rem' }}>de distribuição do Sul do Brasil.</p>
                                <img src={imgLogo} alt="logo" style={{ width: '80%', paddingTop: '2rem' }} />
                            </div>
                        </Col>
                    </Col>
                    <Col sm className='mapa'>
                        <img src={imgMapa} alt="mapa" style={{ width: '40%' }} />
                    </Col>
                </Row>
            </Container>
            <div className="w-100 footer-mob-about">
                <Footer />
            </div>
        </Animate>
    );
}