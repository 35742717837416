import { Button, Col, Container, Row } from "react-bootstrap";
import imgBack from '../../images/fundoprodutos.jpg';
import imgProprioPonto from '../../images/produtos/proprioponto.png';

import { ProductCard } from "./Card";
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { API_URL, axiosconfig } from "../../core";
import './Produtos.css';
import { Product } from "../../interfaces/product";


export function Produtos() {
    const [products, setProductList] = useState<Product[]>([]);
    useEffect(() => {
        axios.get(API_URL + '/api/products?format=json', axiosconfig)
            .then((response: AxiosResponse) => {
                setProductList(response.data);
            });
    }, []);
    return (
        <div className="w-100 pdtop" style={{ backgroundImage: `url(${imgBack})` }}>
            <Container className="w-100">
                <Row style={{ paddingTop: '6rem', margin: '0', display: 'flex', textAlign: 'left', justifyContent: 'center' }}>
                    <Col sm style={{ textAlign: 'center', padding: '1rem' }} className="product-info">
                        <hr style={{ maxWidth: '15%', backgroundColor: '#1dc407', height: '5px', marginLeft: '2rem' }} />
                        <h3 style={{ textAlign: 'left', color: '#1dc407', fontWeight: 'bold', paddingTop: '1rem', marginLeft: '2rem', lineHeight: '0.5' }}>Nossos </h3>
                        <h3 style={{ textAlign: 'left', color: '#1dc407', fontWeight: 'bold', marginLeft: '2rem' }}>Produtos</h3>
                        <p style={{ textAlign: 'left', marginLeft: '2rem', marginRight: '1rem', fontSize: '14px', color: '#FFF' }}>
                            O combustível é substância que, quando queimada,
                            ou seja, entra em contato e reage com oxigênio ou ar, produz calor.
                            Assim, as substâncias classificadas devem conter um ou vários dos elementos combustíveis:
                            carbono, hidrogênio, enxofre, entre outros.
                        </p>
                    </Col>
                    {products?.map(product => (
                        <div key={product.slug}>
                            <ProductCard {...product} />
                        </div>
                    ))}
                </Row>
            
                <Row style={{ margin: '0', display: 'flex', textAlign: 'left', justifyContent: 'center', marginTop: '2rem' }}>
                    <Col sm className="">
                        <p style={{ color: "#FFD904", fontSize: "1.4rem", fontWeight: "bold", lineHeight:"4rem"}}>
                            TENHA SEU PRÓPRIO
                            <p style={{ color: "#FFD904", fontSize: "1.4rem", fontWeight: "bold", lineHeight:"1rem"}}>
                                PONTO DE 
                            </p>
                            <p style={{ color: "#FFD904", fontSize: "1.4rem", fontWeight: "bold", lineHeight:"2rem"}}>
                                ABASTECIMENTO
                            </p>
                        </p>
                    </Col>
                    <Col sm style={{alignItems: 'center', display:'flex', color:"white", fontSize: '14px', textAlign:"justify", marginRight: '3rem'}}>
                       
                            <p>Empresa, cooperativa e indústria podem ter 
                            seu próprio ponto de abastecimento. A stang 
                            disponibiliza toda a estrutura com a tecnologia
                            expertise de seus produtos para os nossos clientes,
                           facilitando o abastecimento de sua frota. </p>
                        
                    </Col>
                    <Col sm style={{alignItems: 'center', display:'flex'}}>
                        <img className="w-100" src={imgProprioPonto}/>
                    </Col>
                </Row>
            

            </Container>

            <Row style={{ margin: "0", paddingTop: "0.5rem" }}>
                <Col md={2} style={{ margin: "0", padding: "0" }}>
                </Col>
                <Col md={10} style={{ margin: "0", padding: "0" }}>
                    <div className="card-orcamento" data-aos="zoom-in-up" >
                        <h3 className="texto-orcamento" > Faça já seu orçamento</h3>
                        <a href="/orcamento"><Button className="button-orcamento"> Clique aqui</Button></a>
                    </div>
                </Col>
            </Row>
            
        </div>
    );
}