import { Card } from "react-bootstrap";
import { API_URL } from "../../core";
import { Product } from "../../interfaces/product";
import './Card.css';

export function ProductCard(product: Product) {
    return (
        <a href={`/produtos#${product?.slug}`}>
            <Card className="text-white gray" data-aos="flip-left" style={{ border: 'none', margin: '0.3rem', borderRadius: '1.6rem' }}>
                <Card.Img src={`${API_URL}${product?.image}`} alt="Card image" style={{ borderRadius: '1rem', maxWidth: '260px' }} />
                <Card.ImgOverlay>
                    <hr style={{ maxWidth: '14%', marginLeft: 0, backgroundColor: "white", height: '3px' }} />
                    <Card.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingTop: '80%', fontWeight: 'bold', fontSize: '1.4rem' }}>
                        <a href={`/produtos#${product?.slug}`} style={{ color: '#FFF' }} >
                            {product?.title}
                        </a>
                    </Card.Title>
                </Card.ImgOverlay>
            </Card>
        </a>
    );
}