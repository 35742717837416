import imgCombustivel from '../../images/combustivel_verde.png';
import './Combustivel.css';

export function Combustivel() {
    return (
        <div className="wrapper">
            <div className="divimage" style={{ backgroundImage: `url(${imgCombustivel})` }}></div>
            <div className="divtext1" data-aos="fade-right"><b>Combustível verde</b></div>
            <div className="divtext2" data-aos="fade-left">Melhor para seu carro, <br /><b>melhor para a natureza!</b></div>
        </div>
    );
}