import { Button, Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import { Header } from "../../header/Header";
import logoImg from '../../images/LOGO_STANG.svg';
import { API_URL, axiosconfig } from "../../core";
import axios from "axios";
import React from 'react';
import Animate from 'react-smooth';

export default class BlogDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pub: {},
        code: {}
    };
  }
  componentDidMount() {
    axios.get(API_URL + `/api/pub?slug=${this.props.match.params.slug}&format=json`, axiosconfig)
        .then(res => {
            this.setState({ pub: res.data });
        })
    axios.get(API_URL + '/api/code?format=json', axiosconfig)
    .then(res => {
        this.setState({ code: res.data });
    })
  }
  formatterDate(dt) {
      return dt ? Intl.DateTimeFormat('pt-BR').format(new Date(dt)) : '';
  }
  render(){
    return (      
      <Animate to="1" from="0" attributeName="opacity">
        <Header />
        <br/><br/>
        <div className="album py-5 bg-light">
          <Container>
            <div style={{textAlign: 'center'}}>
            <span>{this.formatterDate(this.state.pub.pub)}</span>
            <h2>{this.state.pub.title}</h2>
            <h6 style={{ color: 'grey' }}>{this.state.pub.subtitle}</h6>
            <br />
            <img src={`${API_URL}${this.state.pub.image}`} alt={this.state.pub.title} style={{ maxWidth: '60%' }} />
            </div>
            <br/><br/>
            <span style={{ whiteSpace: 'pre-wrap' }} >{this.state.pub.content}</span>
            {this.state.pub.vid && this.state.pub.vid !== '' ?
            <div className="col-lg-12" style={{ textAlign: 'center' }}>
                <br />
                <iframe width="400" height="400" src={`https://www.youtube.com/embed/${this.state.pub.vid}`} title={this.state.pub.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            :
            null
            }
          </Container>
        </div>
        <Container style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
          <Row>
            <Col >
              <Row md={10} style={{ border: "4px solid #66666" }}>
                <Card>
                  <Row>
                    <CardGroup style={{ border: "none", backgroundColor: "#FFF" }}>
                    <Card style={{ border: "none", textAlign: 'center' }}>
                          <Card.Img className="img-news" src={logoImg} />
                          <Card.Body>
                              <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>CÓDIGO DE ÉTICA E CONDUTA</Card.Title>
                              <Card.Text >
                                  {this.state.code.content}
                              </Card.Text>
                          </Card.Body>
                          <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                              <a href={`${API_URL}${this.state.code.document}`} target="_blank" rel="noreferrer"><Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem", fontSize: '0.8rem' }}>Acesse aqui</Button></a>
                          </Card.Footer>
                      </Card>
                      <Card style={{ margin: "20px", border: "none" }}>
                        <p className="complience">COMPLIANCE</p>
                        <Card.Body>
                          <Card.Title style={{ fontSize: "1rem", color: "#0069D9", textAlign: 'center' }}>DENUNCIE IRREGULARIDADES ATRAVÉS DO CANAL DE DENÚNCIA STANG</Card.Title>
                          <Card.Text>
                            Nossa plataforma de denúncia é operada por uma empresa externa especializada em captação de relatos de não
                            conformidade. Os relatos podem ser realizados de forma anônima ou com identificação.
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ border: "none", backgroundColor: "#FFF" }}>
                          <Button variant="danger" style={{ padding: "0.5rem 4rem 0.5rem 4rem" }}>Denunciar</Button>
                        </Card.Footer>
                      </Card>
                    </CardGroup>                  
                  </Row>
                </Card>
              </Row>
            </Col>                    
          </Row>
        </Container>
        <Footer />
      </Animate>
    );
  }
}